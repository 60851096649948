<template>
  <!-- <TheLogo></TheLogo> -->
  <!-- <img alt="B logo" src="./assets/b_pink.png" class="b_logo"> -->
  <!-- <HelloWorld msg="Will you bee my Valentine?"/> -->
  <h1 v-if="!clicked">Will you<img class="h1_img" src="./assets/b_pink.png">my Valentine?</h1>
  <h1 v-if="clicked">YYAAAYYYYY</h1>
  <TheButton v-if="!clicked" @button-clicked="setClicked"></TheButton>
  <div v-if="clicked" class="floating-text"><h1>Elska þig!</h1></div>
  <TheHeart v-if="clicked" @heart-clicked="createFloatingImages"></TheHeart>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
// import TheLogo from './components/TheLogo.vue';
import TheHeart from './components/TheHeart.vue';
import TheButton from './components/TheButton.vue';



export default {
  name: 'App',
  data() {
    return {
      clicked: false,
      imagesProperties: [
        { path: '1', left: '-3%', duration: 17, delay: 1, size: 250 },
        { path: '2', left: '7%', duration: 20, delay: 2, size: 260 },
        { path: '3', left: '17%', duration: 16, delay: 1.5, size: 270 },
        { path: '4', left: '27%', duration: 22, delay: 2.5, size: 280 },
        { path: '9', left: '37%', duration: 19, delay: 0.5, size: 290 },
        { path: '10', left: '47%', duration: 21, delay: 2, size: 300 },
        { path: '7', left: '57%', duration: 17, delay: 3, size: 310 },
        { path: '8', left: '67%', duration: 15, delay: 1, size: 320 },
        { path: '6', left: '77%', duration: 23, delay: 4, size: 330 },
        { path: '5', left: '87%', duration: 20, delay: 2, size: 340 }
      ],

    }
  },
  methods: {
    setClicked() {
      this.clicked = !this.clicked;
      this.createFloatingImages(); // Call a method to create multiple images
    },
    createFloatingImages() {
      this.imagesProperties.forEach(prop => {
        this.createFloatingImage(prop);
      });
    },
    createFloatingImage(prop) {
      const img = document.createElement('img');
      img.src = require(`./assets/img/${prop.path}.jpg`); // Ensure this path is correct
      img.classList.add('floating-image');

      // Apply properties from the array
      img.style.left = prop.left;
      img.style.animationDuration = `${prop.duration}s`;
      img.style.animationDelay = `${prop.delay}s`;
      img.style.height = `${prop.size}px`;
      // img.style.height = 'auto'; // Maintain aspect ratio

      document.body.appendChild(img);

      // Remove the image from the DOM after the animation ends
      img.addEventListener('animationend', () => {
        img.remove();
      });
    }
  },
  components: {
    // HelloWorld,
    // TheLogo,
    TheHeart,
    TheButton,
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
